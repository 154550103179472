import CustomModal from "../CustomModal";
import "./DeleteModal.scss";

const DeleteModal = ({ show, handleClose, handleDelete, item, itemType }) => {
  return (
    <CustomModal
      title={`Delete ${itemType}`}
      isVisible={show}
      onClose={handleClose}
    >
      <div className="delete-modal-body">
        <div className="delete-modal-content">
          <div className="delete-modal-title">
            Are you sure you want to delete this {itemType.toLowerCase()}?
          </div>
          <div className="delete-modal-itemname">{item?.name || item}</div>
        </div>
        <footer className="modal-footer">
          <button
            type="button"
            className="modal-footer-button"
            onClick={() => handleDelete(item?.id || item)}
          >
            Delete
          </button>
        </footer>
      </div>
    </CustomModal>
  );
};

export default DeleteModal;
