import httpArcheo from "../helpers/http-archeo";

export const getComuni = async (filter) => {
  let path = `/get?`;
  filter.forEach((f) => {
    path += `${f.key}=${f.value}&`;
  });
  try {
    const response = await httpArcheo.get(`/api/private/v3/comune${path}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getComuneLayers = async (comuneId) => {
  try {
    const response = await httpArcheo.get(`/api/private/v3/comune/get/${comuneId}/layers`); 
    return response?.data;
  } catch (err) {
    throw err;
  }
};

