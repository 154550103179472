import { useState, useEffect } from "react";
import CustomModal from "../../Common/CustomModal";
import Select from "react-select";
import authorities from "../../../constants/dataStructure/roles.json";
import FormField from "../../FormField/FormField";
import { updateRole } from "../../../api";
import { toast } from "react-toastify";
import "./EditRoleModal.scss";

const EditRoleModal = ({ show, roleData, fetchRoles, handleClose }) => {
  const [selectedAuthorities, setSelectedAuthorities] = useState([]);
  const [color, setColor] = useState(roleData.color || "#ffffff");

  const handleAuthoritiesChange = (selectedOptions) => {
    setSelectedAuthorities(selectedOptions);
  };

  const handleColorChange = (payload) => {
    setColor(payload);
  };

  const handleSubmit = async () => {
    try {
      const submitData = {
        id: roleData.id,
        name: roleData.name,
        authorities: selectedAuthorities.map((authority) => authority.value),
        color,
      };
      await updateRole(submitData);
      handleClose();
      toast.success("Role updated successfully");
      fetchRoles();
    } catch (err) {
      console.error("Error while updating role", err);
      toast.error("Error while updating role");
    }
  };

  useEffect(() => {
    setSelectedAuthorities(
      roleData?.authorities?.map((authority) => ({
        value: authority,
        label: authority,
      }))
    );
  }, [roleData]);
  return (
    <CustomModal isVisible={show} onClose={handleClose} title="Edit Role">
      <div className="edit-role-modal-body">
        <div className="input-wrapper">
          <label htmlFor="roleName" className="input-label">
            Role Name:
          </label>
          <p className="rolename-text">{roleData?.name}</p>
        </div>
        <FormField
          fieldStructure={{
            label: "Colore",
            type: "color",
            editable: true,
          }}
          value={color}
          onChange={handleColorChange}
          disabled={false}
        />
        <div className="input-wrapper">
          <label htmlFor="roles" className="input-label">
            Roles:
          </label>
          <Select
            isMulti
            name="roles"
            options={authorities.roles}
            value={selectedAuthorities}
            placeholder="Select a role."
            onChange={handleAuthoritiesChange}
          />
        </div>
      </div>
      <footer className="modal-footer">
        <button
          type="submit"
          className="modal-footer-button"
          onClick={handleSubmit}
        >
          Update
        </button>
      </footer>
    </CustomModal>
  );
};

export default EditRoleModal;
