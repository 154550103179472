import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import BibliografiaTable from "./BibliografiaTable/BibliografiaTable";
import AddBibliografiaModal from "./AddBibliografiaModal/AddBibliografiaModal";
import AddNewBibliografia from "./AddBibliografiaModal/AddNewBibliografia/AddNewBibliografia";
import { getBibliografia, updateBibliografia } from "../../../api";
import { useAppContext } from "../../Context/AppContext";
import CustomTooltip from "../../CustomTooltip";
import { AddIcon } from "../../../icons";
import "./BibliografiaSection.scss";
import { toast } from "react-toastify";

const BibliografiaSection = ({ edit = false, repertiId, repertiNote }) => {
  const [isAddModalShow, setIsAddModalShow] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [bibliografiaData, setBibliografiaData] = useState([]);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const { setBibliografiaId } = useAppContext();

  const fetchBibliografiaData = useCallback(
    async (page, pageSize) => {
      try {
        const data = await getBibliografia({
          page,
          pageSize,
          "reperto.id": repertiId,
        });
        const formattedData = data?.results.map((result) => ({
          id: result.id,
          titolo: result.titolo,
          label: result.titolo,
          value: result.titolo,
          abbreviazione: result.abbreviazione,
          note: result.note,
        }));
        setResults(data?.results);
        setBibliografiaData(formattedData);

        console.log("bibliografia data", data);
      } catch (error) {
        console.error("Error fetching bibliografia data", error);
      }
    },
    [repertiId]
  );

  const handleUpdateBiliografia = () => {
    console.log("update bibliografia");
    fetchBibliografiaData(1, 10);
  };

  const handleDeleteBibliografia = useCallback(
    async (id) => {
      try {
        const filteredResult = results.find((item) => item.id === id);

        const reperti = filteredResult.reperti;

        const filteredReperti = reperti.filter(
          (item) => item.reperto.id !== repertiId
        );

        const submitData = {
          ...filteredResult,
          reperti: filteredReperti.map((item) => ({
            note: item.note,
            repertoId: item.reperto.id,
          })),
        };

        console.log("submit--data-->>", submitData);
        await updateBibliografia(submitData);
        toast.success("Successfully deleted!");
        fetchBibliografiaData(1, 10);
      } catch (err) {
        console.log("update-error-->>", err);
        toast.error("Delete failed");
      }
    },
    [results, repertiId, fetchBibliografiaData]
  );

  const handleBibliografiaView = useCallback(
    (id) => {
      setBibliografiaId(id);
      navigate("/pubblicazioni/view");
    },
    [navigate, setBibliografiaId]
  );

  useEffect(() => {
    fetchBibliografiaData(1, 10);
  }, [fetchBibliografiaData]);

  return (
    <div>
      <BlockHeader title={"Bibliografia"}>
        {edit && (
          <CustomTooltip text={"Aggiungi Bibliografia"}>
            <button
              className="repertori-button"
              onClick={() => {
                setIsAddModalShow(true);
              }}
            >
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </BlockHeader>
      <BibliografiaTable
        edit={edit}
        bibliografiaData={bibliografiaData}
        handleDeleteBibliografia={handleDeleteBibliografia}
        handleBibliografiaView={handleBibliografiaView}
      />
      {isAddModalShow && (
        <AddBibliografiaModal
          open={isAddModalShow}
          repertiId={repertiId}
          repertiNote={repertiNote}
          selectedData={bibliografiaData}
          handleUpdateBiliografia={handleUpdateBiliografia}
          handleOpenNew={() => setIsAddModal(true)}
          handleClose={() => setIsAddModalShow(false)}
        />
      )}
      {isAddModal && (
        <AddNewBibliografia
          open={isAddModal}
          handleClose={() => setIsAddModal(false)}
        />
      )}
    </div>
  );
};

export default BibliografiaSection;
