import { useState, useCallback } from "react";
import CustomModal from "../../../Common/CustomModal";
import FormField from "../../../FormField/FormField";
import { CopyIcon, CalendarIcon } from "../../../../icons";
import movimentazioneStructure from "../../../../constants/dataStructure/movimentazione.json";
import CustomToggleButton from "../../../CustomToggleButton";
import DatePickerModal from "../../../Common/DatePickerModal/DatePickerModal";
import Select from "react-select";
import { movimentazioneReferentiJson } from "./constant";
import UploadPdfComponent from "../../../Common/UploadPdfComponent/UploadPdfComponent";
import { newMovimentaznione } from "../../../../api";
import "./AddMovimentazioneModal.scss";
import { toast } from "react-toastify";

const AddMovimentazioneModal = ({ show, disabled, repertiId, handleClose }) => {
  const [formData, setFormData] = useState({
    termineProvisionale: true,
    repertoId: repertiId,
  });
  const [isInizioDatePickerShow, setIsInizioDatePickerShow] = useState(false);
  const [isTermineDatePickerShow, setIsTermineDatePickerShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [referentiData, setReferentiData] = useState({});

  const handleFieldChange = useCallback((key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  }, []);

  const handleToggle = useCallback((e, newState) => {
    setFormData((prevData) => ({
      ...prevData,
      termineProvisionale: newState,
    }));
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {}
    );
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setReferentiData((prevData) => ({
      ...prevData,
      tipo: selectedOption.value,
    }));
  };

  const handleInputChange = (e) => {
    setReferentiData((prevData) => ({
      ...prevData,
      valore: e.target.value,
    }));
  };

  const onSubmit = useCallback(async () => {
    try {
      const submitData = {
        ...formData,
        allegati: formData.allegati?.map((item) => item.id),
        referenti: [referentiData],
      };
      console.log(formData, "-->>>", referentiData);
      await newMovimentaznione(submitData);
      toast.success("Movimentazione added successfully");
    } catch (err) {
      console.error("Error adding movimentazione", err);
      toast.error("Error adding movimentazione");
    }
  }, [formData, referentiData]);
  return (
    <CustomModal
      title="Aggiungi Movimentazione"
      isVisible={show}
      onClose={handleClose}
    >
      <div className="add-movimentazione-modal">
        <RepertoIdHeader
          repertiId={repertiId}
          copyToClipboard={copyToClipboard}
        />
        {Object.keys(movimentazioneStructure).map((key) => (
          <FormField
            key={key}
            fieldStructure={movimentazioneStructure[key]}
            value={formData[key]}
            onChange={(newValue) => handleFieldChange(key, newValue)}
            disabled={disabled}
          />
        ))}
        <DateTimePicker
          label="Inizio"
          date={formData?.inizio}
          onClick={() => setIsInizioDatePickerShow(true)}
        />
        <DateTimePicker
          label="Termine"
          date={formData?.termine}
          onClick={() => setIsTermineDatePickerShow(true)}
        />
        {/* <div className="movimentazione-toggle-wrapper">
          <p>Termine Provisionale</p>
          <CustomToggleButton
            initialState={formData?.termineProvisionale}
            onToggle={handleToggle}
          />
        </div> */}
        <ReferentiSelect
          selectedOption={selectedOption}
          handleSelectChange={handleSelectChange}
          handleInputChange={handleInputChange}
        />
        <UploadPdfComponent
          idPdfBibliografia={formData?.allegati}
          disabled={disabled}
          onChange={(newValue) => handleFieldChange("allegati", newValue)}
        />
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={onSubmit}>
          Submit
        </button>
      </footer>

      {isInizioDatePickerShow && (
        <DatePickerModal
          show={isInizioDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFieldChange("inizio", selectedDate);
          }}
          handleClose={() => setIsInizioDatePickerShow(false)}
        />
      )}
      {isTermineDatePickerShow && (
        <DatePickerModal
          show={isTermineDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFieldChange("termine", selectedDate);
          }}
          handleClose={() => setIsTermineDatePickerShow(false)}
        />
      )}
    </CustomModal>
  );
};

const RepertoIdHeader = ({ repertiId, copyToClipboard }) => (
  <div className="reperto-id-header">
    <p className="reperto-id-text">Reperti ID</p>
    <p className="copy-text" onClick={() => copyToClipboard(repertiId)}>
      {repertiId}
      <CopyIcon />
    </p>
  </div>
);

const DateTimePicker = ({ label, date, onClick }) => (
  <div className="movimentazione-date-time-picker">
    <div className="movimentazione-date-time-picker-header">
      <p>{label}</p>
      <button className="date-time-picker-icon" onClick={onClick}>
        <CalendarIcon />
      </button>
    </div>
    <p>{date?.toLocaleString()}</p>
  </div>
);

const ReferentiSelect = ({
  selectedOption,
  handleSelectChange,
  handleInputChange,
}) => (
  <div className="input-select-wrapper">
    <label className="input-select-label" htmlFor="referenti-text">
      Referenti
    </label>
    <div>
      <Select
        options={movimentazioneReferentiJson}
        value={selectedOption}
        placeholder="Select Referenti"
        onChange={handleSelectChange}
      />
      <input
        type="text"
        id="referenti-text"
        name="referenti"
        onChange={handleInputChange}
      />
    </div>
  </div>
);

export default AddMovimentazioneModal;
