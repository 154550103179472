import httpArcheo from "../helpers/http-archeo";

export const getMovimentazione = async (data) => {
  try {
    const response = await httpArcheo.get(
      "/api/private/v3/movimentazione/get",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getMovimentazioneById = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/movimentazione/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getMovimentazioneEvent = async (data) => {
  try {
    const response = await httpArcheo.get(
      "/api/private/v3/movimentazione/event/get",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getMovimentazioneEventById = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/movimentazione/event/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newMovimentaznione = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/movimentazione/save",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newMovimentaznioneUscita = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/movimentazione/event/uscita",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newMovimentaznioneEntrata = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/movimentazione/event/entrata",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateMovimentaznione = async (data) => {
  try {
    const response = await httpArcheo.put(
      "/api/private/v3/movimentazione/update",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
