import React, { useState, useEffect } from 'react';
import MarkdownViewer from './MarkdownViewer';
import TableOfContents from './TableOfContents';
import './UserManual.scss';
import { ArchiveIcon, HourglassIcon, LoginIcon, ManualIcon, SearchIcon } from '../../icons';
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader.jsx";
import { useAppContext } from "../Context/AppContext";

const UserManual = () => {
  const { userProfile  } = useAppContext();
  const [manual, setManual] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [currentSubchapter, setCurrentSubchapter] = useState(null);
  const [content, setContent] = useState("");
  const [tocVisible, setTocVisible] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);


  const chapters = [
    { id: 1, title: "Introduzione", url: "archeo", icon: HourglassIcon, authorities: []},
    { id: 2, title: "PrimoAccesso", url: "primoAccesso", icon: LoginIcon, authorities: []},
    { id: 3, title: "Consultazione dati archeologici", url: "", icon: SearchIcon, authorities: [],
      subchapters:  [
        { id: 3.1, title: "Ricerca per reperto", url: "ricercaReperto", authorities: [] },
        { id: 3.2, title: "Ricerca per cassetta", url: "ricercaCassetta", authorities: []  },
        { id: 3.3, title: "Ricerca per provenienza su WebGis", url: "ricercaWebgis", authorities: []  },
        { id: 3.4, title: "Ricerca in archivio storico", url: "ricercaArchiviostorico", authorities: []  },
        { id: 3.5, title: "Consultazione schede ICCD", url: "consultazioneSchedeICCD", authorities: []  },
      ],
    },{ id: 4, title: "Aggiornamento dei dati archeologici", url: "", icon: ArchiveIcon, authorities: ["ADMIN", "CASSETTA_EDIT", "REPERTO_EDIT"],
        subchapters:  [
          { id: 4.1, title: "Significato di 'gruppo'", url: "significatoDiGruppo", authorities: ["ADMIN", "CASSETTA_EDIT", "REPERTO_EDIT"], },
        ],
    }
  ];
  /* ************************************************************ */
  // ALL API CALLS


  /* ********************************************************* */
  // ALL EFFECTS


  useEffect(() => {
    // Fetch the Markdown content from a file or API
    const fetchContent = async () => {
      const updatedContent = await Promise.all(chapters.map(async (chapter) => {
        let c = { id: chapter.id, title: chapter.title, icon: chapter.icon, content: null, visible: true };
        const documento = chapter.url ? require(`./manualPages/${chapter.url}.md`) : null;
        if (chapter.authorities.length > 0 && userProfile?.roles && !chapter.authorities.some(a => userProfile?.roles.some( r => r.authorities.includes(a)))) c.visible = false;  
        if(documento && c.visible === true) c.content = await fetch(documento).then(res => res.text());
        if (chapter.subchapters) {
          c.subchapters = await Promise.all(chapter.subchapters.map(async (subchapter) => {
            let sc = { id: subchapter.id, title: subchapter.title, content: null, visible: true };
            if (subchapter.authorities.length > 0 && userProfile?.authorities && !subchapter.authorities.some(r => userProfile?.authorities.includes(r))) c.visible = false;  
            const documento = subchapter.url ? require(`./manualPages/${subchapter.url}.md`) : null;
            if(documento && c.visible === true ) sc.content = await fetch(documento).then(res => res.text());
            return sc;
          }));
        }
        return c;
      }));
      setManual(updatedContent);
    };
    fetchContent();
  }, []);


  // SET MANUAL PAGE
  useEffect(() => {
    let content = null;
    if (manual.length > 0 && currentChapter) {
      if(currentSubchapter) {
        content = manual.find((chapter) => chapter.id === currentChapter).subchapters.find((subchapter) => subchapter.id === currentSubchapter).content;
      } else {
        content = manual.find((chapter) => chapter.id === currentChapter).content;
      }
    }
    if(content !== null) {
      setContent(content);
    }
  }, [currentChapter, currentSubchapter, manual]);

 


  /* ********************************************************** */
  // NAVIGATION
  const breadcrumbsData = [
    { id: 1, label: "Guida utente", iconUrl: true },
    { id: 2, label: "" },
  ];

  /* ********************************************************** */

  console.log("Chapter", currentChapter, currentSubchapter);

  return (
    <>
       <DataTableSubHeader
            title={"Guida Utente"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={{}}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
      <div className="manual-container">
        { tocVisible === false && (<div className="table-of-contents-compressed" >  
          <button
                  onClick={() => {
                    setTocVisible(true);
                  }}
                  className="toc-button"
                >
          <ManualIcon width="50px" height="50px"  fillColor="#e15615"/>
          </button>
        </div>
        )}
        { tocVisible === true && ( <div className="table-of-contents-compressed">
        <TableOfContents
          manual={manual}
          currentChapter={currentChapter}
          currentSubchapter={currentSubchapter}
          setCurrentChapter={setCurrentChapter}
          setCurrentSubchapter={setCurrentSubchapter}
        /> 
         </div> )}
        <div className="table-of-contents-uncompressed">
          <TableOfContents
            manual={manual}
            currentChapter={currentChapter}
            currentSubchapter={currentSubchapter}
            setCurrentChapter={setCurrentChapter}
            setCurrentSubchapter={setCurrentSubchapter}
          />
        </div>
        <div className="chapter-content"  onClick={() => setTocVisible(false)}>
        <MarkdownViewer content={content} />
        </div>
      </div>
    </>
  );
};

export default UserManual;