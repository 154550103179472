import "./MapViewPage.scss";
import React, { useEffect, useState, useRef } from "react";
import {
  MapIcon,
  EditIcon,
  HandIcon,
  InfoIcon,
  LayerIcon,
  RotateLeft,
  LegendIcon,
  WorldIcon,
} from "../../icons/index";
import { SelectComune } from "./SelectComune";
import {
  BackgroundMap,
  LayersCard,
  Legend,
  ManageLayers,
  SocialIcon,
  DeleteLayers,
  UploadedFileInfo,
} from "../index";
import { OLMap } from "./openLayerMap/openLayerMap";
import ReactModal from "../ReactModal/ReactModal";
import LayerUploadModal from "./LayerUploadModal/LayerUploadModal";
import backgroundMaps from "../../constants/backgroundMaps";
import { useAppContext } from "../Context/AppContext";
import FeatureSummary from "../FeatureSummary";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import { getComuni } from "../../api";

const socialIcons = [
  {
    icon: <MapIcon />,
    isProtected: false,
    label: "Seleziona il sito o il comune",
    visible: true,
  },
  {
    icon: <LayerIcon />,
    isProtected: false,
    label: "Seleziona i layer",
    visible: true
  },
  {
    icon: <LegendIcon />,
    isProtected: true,
    label: "Legenda",
    isVisible: false
  },
  {
    icon: <HandIcon />,
    isProtected: false,
    label: "Pan map",
    isVisible: true
  },
  {
    icon: <InfoIcon />,
    isProtected: false,
    label: "Informazioni feature",
    isVisible: true
  },
  {
    icon: <EditIcon />,
    isProtected: true,
    label: "Gestisci Layer",
    isVisible: true
  },
  {
    icon: <WorldIcon />,
    isProtected: false,
    label: "Cambia mappa di sfondo",
    isVisible: true
  },
  {
    icon: <RotateLeft />,
    isProtected: false,
    label: "Reset",
    isVisible: true
  },
];


const legends = ["Layer 1", "Layer 2", "Layer 3"];

export default function MapViewPage() {
  const { comuni, layers, setComuni, setLayers, userProfile  } = useAppContext();
  const [currentSelectedSocialIcon, setCurrentSelectedIcon] = useState(-1);
  const [isDeleteLayerVisible, setDeleteLayerVisible] = useState(false);
  const [isUploadFileVisible, setUploadFileVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [isFileUploaded, setFileUploaded] = useState(false);
  const [layersMenu, setLayersMenu] = useState([]);
  const [selectedComune, setSelectedComune] = useState(null);
  const [properties, setProperties] = useState(null);
  const [isEyeOpen, setIsEyeOpen] = useState(true);
  const [legendStyle, setLegendStyle] = useState("");
  const [subChild, setSubChild] = useState("");
  const [isSelected, setIsSelected] = useState();
  const [title, setTitle] = useState({});
  const titleRef = useRef({});
  const [selectedBackgroundMap, setBackgroundMap] = useState(backgroundMaps[0]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const { mapViewTarget } = useAppContext();

  // CHECK IF USER HAS GIS PRIVILEGES
  const isGisManager = userProfile?.roles?.find(role => role?.authorities?.find(authority => authority === "ADMIN"));

  const listComuni = async () => {
    try {
      const data = await getComuni([
        {key: "pageSize", value: 1000},
        {key: "page", value: 1},
        {key: "sort", value: "nome,asc"}]);
      if(Array.isArray(data.results)) {
        setComuni(data.results.filter(comune => comune.nome!=="ITALIA"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(`Authorization error fetching "comuni"`);
      } else {
        console.error(`Error fetching "comuni"`, error);
      }
    }
  };

  // GET THE UPDATED LIST OF MUNICIPALITIES
  useEffect(() => {
    listComuni();
    return () => {};
  }, []);

  // UPDATE LAYERS MENU
  useEffect(() => {
    const layersMenu = layers?.map((layer) => {
      return {
        id: layer.id,
        title: layer.tipo,
        isVisible: layer.vectorLayer.getVisible(),
        isChecked: false,
        onFocus: false
      };
    });
    setLayersMenu(layersMenu);
  }, [layers]);

  const [layerTitle, setLayerTitle] = useState("");
  let currentSocialToolTipOpen = <></>;

  const handleFeatureProperties = (...values) => {
    setProperties(...values);
    setCurrentSelectedIcon(4);
  };

  const handleSelectedComune = (comune) => {
    setSelectedComune(comune);
  };

  const handleLayerVisibility = (comune) => {
    setSelectedComune(comune);
  };

  const handleSetLayers = (layers) => {
    setLayers();
  };

  const handleLegendImage = (props) => {
    setLegendStyle(props);
  };

  //  **** HANDLE LAYER VISIBILITY  ****
  const handleCallBackLayerVisibility = (props) => {
    console.log("===| PROPS", props);
    setSubChild(props);
    const selected = layers.find((layer) => layer.tipo === props.title);
    console.log(`===| LAYER ${selected.tipo} is visible: `, props.isVisible);
    if (selected) {
      selected.vectorLayer.setVisible(props.isVisible);
      setLayersMenu( prev => prev.map (layer => { return layer.title === selected.tipo ? {...layer, isVisible: props.isVisible} : layer})); 
    }
    console.log(`===| DONE`);
  };

  const handleCallBackCheckLayer = (props) => {
    const selected = layers.find((layer) => layer.tipo === props.title);
    if (selected) {
      setLayersMenu( prev => prev.map (layer => { return layer.title === selected.tipo ? {...layer, isChecked: props.isChecked} : layer})); 
    }
  };

  const handleCallBackLayerTitle = (props) => {
    setLayerTitle(props.layerTitle);
  };

  const handleDeleteLayers = async () => {
    const token = localStorage.getItem("authToken");
    let removed = false;
    layersMenu?.filter((layer) => layer.isChecked).forEach(async (layer) => {
      try {
        const response = await axios.delete(
          `${config.archeoBaseUrl}/api/private/v3/layer/delete/${layer.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setDeleteLayerVisible(false);
          toast.success(`Layer ${layer.title} eliminato con successo`);
          setLayers(layers.filter((layer) => layer.id !== selectedLayer.id));
          removed = true;
        }
      } catch (err) {
        console.error(err);
      }
    });
    if(removed) {
      setTimeout(() => {
        window.location.reload();
      }, 20); // 2000 milliseconds = 2 seconds
    }
  };

  const handleCallBackTitle = (props) => {
    setIsSelected(props.isSelected);
    console.log(props.isSelected, "props.isSelected");
    setTitle(props.title);
  };
  const handleOnCloseSocialIcon = () => {
    setCurrentSelectedIcon(-1);
  };
  const handleOnNextFeatureClick = () => {};
  const handleOnMinimizeFeature = () => {};

  const handleOnClickSocialIcon = (index) => {
    setCurrentSelectedIcon(index);
  };
  switch (currentSelectedSocialIcon) {
    case 0: {
      currentSocialToolTipOpen = (
        <SelectComune
          oselectedComune={selectedComune}
          handleOnCloseSocialIcon={handleOnClickSocialIcon}
          onSelectComune={handleSelectedComune}
        />
      );
      break;
    }
    case 1: {
      currentSocialToolTipOpen = (
        <LayersCard
          title={`${selectedComune.nome}: layer disponibili`}
          // layers={layers}
          layersMenu={layersMenu}
          hasEyes={true}
          // dataList={layersDataList}
          crossClickHandler={handleOnCloseSocialIcon}
          className="municipality-info-card-wrapper"
          handleCallBackLayerVisibility={handleCallBackLayerVisibility}
          handleCallBackLayerTitle={(value) => {
            handleCallBackLayerTitle(value);
          }}
          handleCallBackTitle={(value) => {
            handleCallBackTitle(value);
          }}
          mainTitle={title}
          layerTitle={layerTitle}
        />
      );
      break;
    }
    case 2: {
      currentSocialToolTipOpen = (
        <Legend
          legends={legends}
          onCrossClick={handleOnCloseSocialIcon}
          legendStyle={legendStyle}
        />
      );
      break;
    }
    case 3: {
      currentSocialToolTipOpen = <></>;
      break;
    }
    case 4: {
      currentSocialToolTipOpen = (
        <FeatureSummary
          closeClick={handleOnCloseSocialIcon}
          minimizeClick={handleOnMinimizeFeature}
          nextClick={handleOnNextFeatureClick}
          properties={properties}
        />
      );
      break;
    }
    case 5: {
      currentSocialToolTipOpen = (
        <ManageLayers
          onCrossClick={handleOnCloseSocialIcon}
          onDeleteClick={() => {
            setDeleteLayerVisible(true);
            handleOnCloseSocialIcon();
          }}
          onUploadFile={() => {
            setUploadFileVisible(true);
            handleOnCloseSocialIcon();
          }}
        />
      );
      break;
    }
    case 6: {
      currentSocialToolTipOpen = (
        <BackgroundMap
          onClose={handleOnCloseSocialIcon}
          onSelect={(backgroundMap) => {
            setBackgroundMap(backgroundMap);
          }}
          backgroundMaps={backgroundMaps}
        />
      );
      break;
    }
    case 7: {
      currentSocialToolTipOpen = <></>;
      setSelectedComune(comuni?.find((comune) => comune.nome === "Sibari"));
      setCurrentSelectedIcon(-1);
      break;
    }
    default: {
      currentSocialToolTipOpen = <></>;
    }
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setUploadFileVisible(false);
      setFileUploaded(true);
    }
  };

  const closeReactModal = () => {
    if (isUploadFileVisible) {
      setUploadFileVisible(false);
    } else if (isDeleteLayerVisible) {
      setDeleteLayerVisible(false);
    } else if (isFileUploaded) {
      setFileUploaded(false);
    }
  };

  return (
    <>
      <main className="map-view-main-container">
        <div className="ol-map-container-wrapper-class">
          <OLMap
            backgroundMap={selectedBackgroundMap} // checked ok
            className="ol-map-container" // ?
            handleCallBackLayerTitle={(layerTitle) =>
              handleCallBackLayerTitle({ layerTitle })
            } //??
            handleCallBackTitle={(Title) => handleCallBackTitle({ Title })} // ??
            handleFeatureProperties={handleFeatureProperties} // indentity on map (??)
            isEyeOpen={isEyeOpen} // ?
            title={title} // ?
            layerTitle={layerTitle} //. ?
            handleLegendImage={(value) => {
              // ??
              handleLegendImage(value);
            }}
            mapViewTarget={mapViewTarget} // Checked OK
            titleRef={titleRef.current} // ?
            isSelected={isSelected} // ?
            selectedComune={selectedComune} // checked ok
            setSelectedComune={setSelectedComune} // checkedok
            layers={layers} // checked ok
            setLayers={setLayers} // checked ok
          ></OLMap>
        </div>
        <aside className="sidebar">
          {socialIcons.map(({ icon, isProtected, label, isVisible }, index) => {
            if (!isVisible || (isProtected && !isGisManager) ) return null;
            return (
              <SocialIcon
                key={index}
                icon={React.cloneElement(icon, {
                  fillColor:
                    currentSelectedSocialIcon === index ? "#fff" : "#E15615",
                })}
                isActive={index === currentSelectedSocialIcon}
                onClick={() => {
                  handleOnClickSocialIcon(index);
                }}
                label={label}
              />
            );
          })}
        </aside>
        {currentSocialToolTipOpen}
        <ReactModal
          isOpen={isDeleteLayerVisible || isUploadFileVisible || isFileUploaded}
          onClose={closeReactModal}
        >
          {isUploadFileVisible && (
            <LayerUploadModal
              title="UPLOAD RECORD (ONE AT A TIME)"
              selectedComune={selectedComune}
              closeModal={() => setUploadFileVisible(false)}
              handleFileUpload={handleFileUpload}
            />
          )}
          {isDeleteLayerVisible && (
            <DeleteLayers
              layersMenu={layersMenu}
              handleCallBackCheckLayer={handleCallBackCheckLayer}
              crossClickHandler={() => setDeleteLayerVisible(false)}
              onDelete={handleDeleteLayers}
              onCancel={() => setDeleteLayerVisible(false)}
            />
          )}
          {isFileUploaded && (
            <UploadedFileInfo
              fileName={uploadedFile?.name}
              onCross={() => setFileUploaded(false)}
              onAddLayer={() => setFileUploaded(false)}
              onCancel={() => setFileUploaded(false)}
            />
          )}
        </ReactModal>
      </main>
    </>
  );
}
