import { useState } from "react";
import CustomModal from "../CustomModal";
import { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

const DatePickerModal = ({ show, handleSubmit, handleClose }) => {
  const [value, setValue] = useState(null);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onSubmit = () => {
    console.log(value);
    const selectedDate = value
      ? new Date(
          value.year,
          value.month.index,
          value.day,
          value.hour,
          value.minute
        )
      : new Date();
    handleSubmit(selectedDate);
    handleClose();
  };
  return (
    <CustomModal title="Seleziona Data" isVisible={show} onClose={handleClose}>
      <div>
        <Calendar
          value={value}
          onChange={onChange}
          plugins={[<TimePicker />]}
        />
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={onSubmit}>
          Submit
        </button>
      </footer>
    </CustomModal>
  );
};

export default DatePickerModal;
