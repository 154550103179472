import React from "react";
import "./BlockHeader.scss";

const BlockHeader = ({
  title,
  children,
}) => {
  return (
    <div className="block-section-header">
      <p className="section-title">{title}</p>
      {children}
    </div>
  );
};

export default BlockHeader;
