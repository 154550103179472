// MarkdownViewer.jsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './UserManual.scss';
import styled from 'styled-components';

/*
const MarkdownContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-family: Arial, sans-serif;
`;
*/

const MarkdownViewer = ({ content }) => {
  return (
    <div className="markdown-content">
      <ReactMarkdown
        children={content}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                style={dark}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
};

export default MarkdownViewer;