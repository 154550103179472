// import axios from 'axios';
// import config from '../config';

// const client = axios.create({
//   baseURL: `${config.archeoBaseUrl}/api/private/v3/group/`,
// });

// client.interceptors.request.use((conf) => {
//   const token = localStorage.getItem("authToken");
//   if (token) {
//     conf.headers.Authorization = `Bearer ${token}`;
//     conf.headers.accept = "*/*";
//   }
//   return conf; // This should return conf, not config
// }, (error) => {
//   return Promise.reject(error);
// });

// // GET THE LIST OF USERS
// export const getGroups = async () => {
//   const response = await client.get(`/get`);
//   return response.data.groups;
// };

import httpArcheo from "../helpers/http-archeo";

export const getGroups = async () => {
  try {
    const response = await httpArcheo.get("/api/private/v3/group/get");
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const saveGroup = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/group/save", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateGroup = async (data) => {
  try {
    const response = await httpArcheo.put("/api/private/v3/group/update", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteGroup = async (name) => {
  try {
    const response = await httpArcheo.delete(
      `/api/private/v3/group/delete/${name}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
