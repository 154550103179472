import "./UscitaEventModal.scss";

import React, { useState } from "react";
import { CopyIcon } from "../../../../icons";
import CustomModal from "../../../Common/CustomModal";
import UploadPdfComponent from "../../../Common/UploadPdfComponent/UploadPdfComponent";

const UscitaEventModal = ({ isModalOpen, disabled, closeModal }) => {
  return (
    <CustomModal
      isVisible={isModalOpen}
      onClose={closeModal}
      title="Uscita Event"
    >
      <div className="uscita-event-modal-container">
        <RepertoIdHeader />
        <UploadPdfComponent
        //   idPdfBibliografia={formData?.allegati}
          disabled={disabled}
        //   onChange={(newValue) => handleFieldChange("allegati", newValue)}
        />
      </div>
    </CustomModal>
  );
};

const RepertoIdHeader = ({ repertiId, copyToClipboard }) => (
  <div className="reperto-id-header">
    <p className="reperto-id-text">Reperti ID</p>
    <p className="copy-text" onClick={() => copyToClipboard(repertiId)}>
      {repertiId}
      <CopyIcon />
    </p>
  </div>
);

export default UscitaEventModal;
