import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Movimentazione.scss";
import { useNavigate } from "react-router-dom";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader.jsx";
import DataDropdown from "../DataDropdown/index.jsx";
import {
  AddIcon,
  CloudIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  FilterIcon,
} from "../../icons/index.js";
import { SubHeaderMobileOption } from "../SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { getMovimentazione } from "../../api";
import { toast } from "react-toastify";
import config from "../../config.js";

const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Movimentazione", iconUtl: true },
];

function Movimentazione() {
  const navigate = useNavigate();
  const [isMenuActive, setMenuActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const subHeaderRightNavOptions = {
    icons: [
      false && {
        icon: <CloudIcon />,
        onClick: () => {
          console.log("Upload");
        },
        label: "Upload",
      },
      false &&{
        icon: <AddIcon />,
        label: "Add",
        onClick: () => {
          navigate("/movimentazione/add");
          return null;
        },
      },
      {
        icon: <FilterIcon />,
        onClick: () => {
          console.log("Filter");
        },
        label: "Filter",
      },
      {
        icon: <DownloadIcon />,
        label: "Download",
        onClick: () => {
          console.log("Download");
        },
      },
    ],
    components: [<DataDropdown defaultSelected="MOVIMENTAZIONE" />],
  };

  const fetchMovimentazione = useCallback(async () => {
    try {
      const response = await getMovimentazione({ pageSize, page: currentPage });
      console.log("response", response);
    } catch (err) {}
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchMovimentazione();
  }, [fetchMovimentazione]);

  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"MOVIMENTAZIONI"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Movimentazione;
