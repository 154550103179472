import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GroupsDetailsTable.scss";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader";
import ItemsTable from "../ItemsTable/ItemsTable";
import { AddIcon, RightArrowIcon, EditIcon, CrossIcon } from "../../icons";
import Dropdown from "../Dropdown/Dropdown.jsx";
import { toast } from "react-toastify";
import { getGroups, deleteGroup } from "../../api";
import useTransformData from "../../hooks/useTransformData.js";
import AddGroupModal from "./AddGroupModal/AddGroupModal.jsx";
import DeleteModal from "../Common/DeleteModal/DeleteModal.jsx";

const selectedHeaders = [
  { key: "name", label: "Nome utente", width: "300px", sortable: true },
  { key: "color", label: "Colore", width: "300px", sortable: false },
  { key: "actions", label: "", width: "300px", sortable: false },
];

const breadcrumbsData = [
  {
    id: 1,
    label: `Administration ${localStorage.getItem("username")}`,
    iconUrl: true,
  },
  {
    id: 2,
    label: "GROUPS",
    iconUrl: "",
  },
];

const config = {
  fields: [
    {
      name: "name",
      type: "text",
    },
    {
      name: "color",
      type: "color",
    },
  ],
};

const GroupsDetailsTable = () => {
  const navigate = useNavigate();
  // const [fetchedGroupsData, setFetchedGroupsData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [isEditing, setEditing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  const transformedData = useTransformData(
    tableData,
    isEditing,
    setIsEditModalOpen,
    setIsDeleteModalOpen,
    setSelectedGroup,
    config
  );

  const editingOptions = (
    <div className="editing-tools-button-wrapper">
      <button
        className="action-button cancel-button"
        onClick={() => setEditing(false)}
      >
        <CrossIcon />
        <span>Cancel</span>
      </button>
      <button
        className="action-button save-button"
        onClick={() => setEditing(false)}
      >
        <AddIcon fillColor="white" />
        <span>Save</span>
      </button>
    </div>
  );

  const subHeaderRightNavOptions = {
    icons: isEditing
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => setEditing(false),
            label: "Back",
          },
          {
            icon: <AddIcon onClick={() => setIsAddModalOpen(true)} />,
            onClick: () => {
              setIsAddModalOpen(true);
            },
            label: "Add",
          },
        ]
      : [
          {
            icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
            onClick: () => setEditing(!isEditing),
            label: "Edit",
          },
        ],

    components: isEditing
      ? [editingOptions]
      : [
          <Dropdown
            dropdownTexts={[
              {
                label: "UTENTI",
                onClick: () => {
                  navigate("/users");
                },
              },
              {
                label: "GROUPS",
                onClick: () => {
                  navigate("/groups");
                },
              },
              {
                label: "ROLES",
                onClick: () => {
                  navigate("/roles");
                },
              },
            ]}
            defaultSelected="GROUPS"
          />,
        ],
  };

  const fetchGroups = useCallback(async () => {
    try {
      const response = await getGroups();
      setTableData(response.groups);
      console.log("Groups: ", response);
    } catch (error) {
      toast.error("Error fetching groups");
    }
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      await deleteGroup(selectedGroup.name);
      await fetchGroups();
      toast.success("Group deleted successfully");
    } catch (err) {
      console.error("Error while deleting group", err);
      toast.error("Error while deleting group");
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [selectedGroup, fetchGroups]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  console.log("Table Data: ", transformedData);

  return (
    <div className="groups-details-table">
      <DataTableSubHeader
        title={"GROUPS"}
        breadcrumbsData={breadcrumbsData}
        subHeaderRightNavOptions={subHeaderRightNavOptions}
        isMenuActive={isMenuActive}
        setMenuActive={() => setMenuActive(!isMenuActive)}
      />
      <ItemsTable
        data={transformedData}
        headers={selectedHeaders}
        isEditing={isEditing}
      />
      {isAddModalOpen && (
        <AddGroupModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          fetchGroups={fetchGroups}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          show={isDeleteModalOpen}
          item={selectedGroup}
          handleClose={() => setIsDeleteModalOpen(false)}
          handleDelete={handleDelete}
          itemType="Group"
        />
      )}
    </div>
  );
};

export default GroupsDetailsTable;
