import DataTable from "react-data-table-component";
import "./MovimentazioneSectionTable.scss";

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "#f4f4f4",
    },
  },
};

const MovimentazioneSectionTable = ({ movimentazioneData }) => {
  const columns = [
    {
      name: "Titolo",
      selector: (row) => row.titolo,
      sortable: true,
    },
    {
      name: "Abbreviazione",
      selector: (row) => row.abbreviazione,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
  ];
  return (
    <div>
      <DataTable
        columns={columns}
        data={movimentazioneData}
        customStyles={customStyles}
      />
    </div>
  );
};

export default MovimentazioneSectionTable;
