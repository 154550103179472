import React from "react";
import {
  AddIcon,
  CloudIcon,
  DownloadIcon,
  FilterIcon,
} from "../../../icons/index.js";
import DataDropdown from "../../DataDropdown";

export const subHeaderRightNavOptions = (userCanCreate, handleFilterIconClick, navigate) => ({
  icons: [
    false && {
      icon: <CloudIcon />,
      onClick: () => {
        console.log("Upload");
      },
      label: "Upload",
    },
    userCanCreate && {
      icon: <AddIcon />,
      label: "Add",
      onClick: () => {
        navigate("/archivio/add");
        return null;
      },
    },
    {
      icon: <FilterIcon />,
      onClick: handleFilterIconClick,
      label: "Filter",
    },
    {
      icon: <DownloadIcon />,
      label: "Download",
      onClick: () => {
        console.log("Download");
      },
    },
  ],
  components: [<DataDropdown defaultSelected="ARCHIVIO STORICO" />],
});
