// import axios from 'axios';
// import config from '../config';
import httpArcheo from "../helpers/http-archeo";

export const getUsers = async (data) => {
  try {
    const response = await httpArcheo.get("/api/private/v3/user/get", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getMe = async () => {
  try {
    const response = await httpArcheo.get("/api/private/v3/user/get/me");
    return response?.data?.user;
  } catch (err) {
    throw err;
  }
};

export const updatePassword = async (newPassword) => {
  try {
    const response = await httpArcheo.put(
      "/api/private/v3/user/update/password",
      { newPassword }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const saveUser = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/user/save", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const setUserRoles = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/user/role/set",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const setUserGroups = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/user/group/set",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (username) => {
  try {
    const response = await httpArcheo.delete(
      `/api/private/v3/user/delete/${username}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateAdminPwd = async (data) => {
  try {
    const response = await httpArcheo.put(
      "/api/private/v3/user/update/password/admin",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

// const client = axios.create({
//   baseURL: `${config.archeoBaseUrl}/api/private/v3/user/`,
// });

// client.interceptors.request.use((conf) => {
//   const token = localStorage.getItem("authToken");
//   if (token) {
//     conf.headers.Authorization = `Bearer ${token}`;
//     conf.headers.accept = "*/*";
//   }
//   return conf; // This should return conf, not config
// }, (error) => {
//   return Promise.reject(error);
// });

// // GET THE LIST OF USERS
// export const getUsers = async () => {
//   const response = await client.get(`/get`);
//   return response.data.users;
// };

// // GET MY PROFILE DATA
// export const getMe = async () => {
//   const response = await client.get(`/get/me`);
//   return response.data.user;
// };

// // UPDATE USER PASSWORD
// export const updatePassword = async (newPassword) => {
//   const response = await client.put(`/update/password`, {newPassword});
//   return response.data.users;
// };
