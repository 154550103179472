import { useState, useCallback } from "react";
import CustomModal from "../../Common/CustomModal";
import FormField from "../../FormField/FormField";
import Select from "react-select";
import { saveRole } from "../../../api";
import { toast } from "react-toastify";
import authorities from "../../../constants/dataStructure/roles.json";
import "./AddRoleModal.scss";

const AddRoleModal = ({ isOpen, onClose, fetchRoles }) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleChange = useCallback(
    (key, value) => {
      setFormData({
        ...formData,
        [key]: value,
      });
    },
    [formData]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const submitData = {
        ...formData,
        authorities: formData.authorities.map((authority) => authority.value),
      };
      console.log("formData", submitData);
      await saveRole(submitData);
      await fetchRoles();
      toast.success("Role saved successfully");
    } catch (err) {
      console.error("Error while saving role", err);
      toast.error("Error while saving role");
    } finally {
      onClose();
    }
  }, [formData, onClose, fetchRoles]);
  return (
    <CustomModal title={"Add New Role"} isVisible={isOpen} onClose={onClose}>
      <div className="add-role-modal-body">
        <div className="input-wrapper">
          <label htmlFor="name" className="input-label">
            Role Name
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
        <FormField
          fieldStructure={{
            label: "Colore",
            type: "color",
            editable: true,
          }}
          value={formData.color}
          onChange={(newValue) => handleChange("color", newValue)}
          disabled={false}
        />
        <div className="input-wrapper">
          <label htmlFor="roles" className="input-label">
            Roles:
          </label>
          <Select
            isMulti
            name="roles"
            options={authorities.roles}
            value={formData.authorities || []}
            placeholder="Select a role."
            onChange={(newValue) => handleChange("authorities", newValue)}
          />
        </div>
      </div>
      <footer className="modal-footer">
        <button
          type="submit"
          className="modal-footer-button"
          onClick={handleSubmit}
        >
          Add
        </button>
      </footer>
    </CustomModal>
  );
};

export default AddRoleModal;
